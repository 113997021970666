.contact-form {
    background-color: #f8f8f8;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
  }
  
  .contact-form h2 {
    margin-bottom: 1.5rem;
    color: #333;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .form-group textarea {
    height: 150px;
    resize: vertical;
  }
  
  button[type="submit"] {
    background-color: #cf621d;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  button[type="submit"]:hover {
    background-color: #a84e17;
  }
  
  button[type="submit"]:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .success-message {
    text-align: center;
    color: #4CAF50;
    font-weight: bold;
    padding: 1rem;
    background-color: #e8f5e9;
    border-radius: 4px;
  }
  
  .contact-form .error {
    color: #d32f2f;
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }