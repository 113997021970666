.youth-summit {
  background: linear-gradient(135deg, #cf621d, #ff8c00);
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.youth-summit-promo,
.youth-summit-video {
  width: 100%;
  margin: 0 auto 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.youth-summit-promo {
  background-image: url('../assets/images/youth-summit-promo.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.youth-summit-promo > * {
  position: relative;
  z-index: 1;
  max-width: 100%;
}

.youth-summit-promo h2,
.youth-summit-promo p {
  color: white;
  text-shadow: 
      2px 2px 4px rgba(0,0,0,0.7),
      0 0 10px rgba(255,255,255,0.5);
  animation: textAppear 1s ease-out, textGlow 2s ease-in-out infinite alternate;
}

.youth-summit-promo h2 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

.youth-summit-promo p {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.4;
  color: #ffffff;
  animation: sparkle 2s infinite;
  margin-bottom: 1.5rem;
}

.youth-summit-promo p:hover {
  transform: translateZ(20px) scale(1.05);
}

.highlight-team {
  color: #ff6b00; /* Couleur orange de votre code couleur */
  font-weight: 700; /* Rend le texte plus gras */
  text-shadow: 
    0 0 5px rgba(255, 107, 0, 0.7), /* Ajoute une légère lueur orange */
    1px 1px 2px rgba(0, 0, 0, 0.8); /* Garde une ombre pour la lisibilité */
}

@keyframes sparkle {
  0%, 100% { text-shadow: 
    -1px -1px 0 #4da6ff,  
    1px -1px 0 #4da6ff;
  }
  50% { text-shadow: 
    -1px -1px 0 #0066cc,
    1px -1px 0 #0066cc
  }
}

@keyframes textAppear {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes textGlow {
  from {
      text-shadow: 
          2px 2px 4px rgba(0,0,0,0.7),
          0 0 10px rgba(255,255,255,0.5);
  }
  to {
      text-shadow: 
          2px 2px 4px rgba(0,0,0,0.7),
          0 0 20px rgba(255,255,255,0.8);
  }
}

.youth-summit-video {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  text-align: center;
}

.video-container {
  width: 100%;
  max-width: 720px;
  position: relative;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  aspect-ratio: 9 / 16;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  cursor: pointer;
  z-index: 2;
}

.play-button img {
  width: 100%;
  height: 100%;
}

.video-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.video-controls button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.video-controls button svg {
  width: 24px;
  height: 24px;
  fill: white;
}

.countdown {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(255,255,255,0.3);
}

.countdown-item {
  text-align: center;
}

.countdown-item span:first-child {
  font-size: 2.5rem;
  font-weight: bold;
  display: block;
}

.countdown-item span:last-child {
  font-size: 1rem;
  text-transform: uppercase;
}

.countdown-item span {
  color: white;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
}

@media (min-width: 1200px) {
  .youth-summit {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
  .youth-summit-promo {
    flex: 2;
    margin-right: 1rem;
  }
  .youth-summit-video {
    flex: 1;
    margin-left: 1rem;
  }
}

@media (max-width: 1199px) {
  .youth-summit-promo {
    aspect-ratio: auto;
    height: auto;
    min-height: 50vh;
  }
}

@media (max-width: 767px) {
  .youth-summit {
    padding: 1rem;
  }
  .youth-summit-promo,
  .youth-summit-video {
    width: 100%;
    margin: 0 0 1rem 0;
    padding: 1rem;
  }
  .youth-summit-promo h2 {
    font-size: 2.5rem;
  }
  .youth-summit-promo p {
    font-size: 1.1rem;
  }
  .countdown-item span:first-child {
    font-size: 2rem;
  }
}