.hero {
    position: relative;
    height: 100vh; /* Hauteur pleine pour les écrans larges */
    overflow: hidden;
}

.hero video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.hero-content {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
    color: white;
}

.hero h1 {
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Arial Black', Gadget, sans-serif;
}

.hero-description {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    font-weight: 300;
    line-height: 1.4;
}

.btn-cta {
    background-color: #ff6b00;
    color: white;
    padding: 1rem 2.5rem;
    text-decoration: none;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1.2rem;
    transition: background-color 0.3s, transform 0.3s;
    text-transform: uppercase;
}

.btn-cta:hover {
    background-color: #ff8c00;
    transform: scale(1.05);
}

/* Styles pour les appareils mobiles */
@media (max-width: 768px) {
    .hero {
        height: 60vh; /* Réduire la hauteur sur mobile */
    }

    .hero video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .hero-content {
        padding: 1rem;
    }

    .hero h1 {
        font-size: 2rem; /* Réduire la taille du titre sur mobile */
    }

    .hero-description {
        font-size: 1.2rem;
        line-height: 1.5;
    }
    .btn-cta {
        font-size: 0.9rem; /* Ajuster la taille du bouton sur mobile */
        padding: 0.7rem 1.5rem;
    }
}