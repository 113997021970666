.teams {
    padding: 4rem 2rem;
    background-color: #f5f5f5;
}

.teams h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #2a2a2a;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.team-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.team-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.team-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.team-card h3 {
    font-size: 1.5rem;
    margin: 1rem;
    color: #2a2a2a;
}

.team-card p {
    margin: 0 1rem 1rem;
    color: #666;
}

.team-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.team-details.show {
    max-height: 300px;
}

.team-details h4 {
    margin: 1rem 1rem 0.5rem;
    color: #2a2a2a;
}

.team-details ul {
    margin: 0 1rem 1rem;
    padding-left: 1.5rem;
    color: #666;
}

.team-details p {
    margin: 0 1rem 1rem;
    color: #666;
}

@media (max-width: 768px) {
    .teams h2 {
        font-size: 2rem;
    }

    .team-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .team-card h3 {
        font-size: 1.2rem;
    }
}