.solidarity-shop {
  background-size: cover;
  background-position: center;
  padding: 2rem;
  border-radius: 10px;
  margin: 2rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solidarity-shop-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  width: 100%;
  max-width: 600px;
}

.solidarity-shop h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.shop-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.shop-code-container {
  margin-bottom: 2rem;
}

.code-instruction {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.code-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.code-display {
  display: inline-flex;
  align-items: center;
  background-color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  user-select: none;
}

.code-display:hover {
  transform: scale(1.05);
}

.code-display:active {
  transform: scale(0.98);
}

.code {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2a2a2a;
  letter-spacing: 2px;
  user-select: none;
}

.copy-button {
  background: none;
  border: none;
  padding: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.copy-button:hover {
  color: #333;
}

.copied-text {
  color: #4CAF50;
  font-size: 1.5rem;
  font-weight: bold;
}

.copied-feedback {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.copied-feedback.show {
  opacity: 1;
}

.shop-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.btn-shop {
  background-color: #4CAF50;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  border: 2px solid transparent;
  white-space: nowrap;
  max-width: 100%;
}

.btn-shop:hover {
  background-color: white;
  color: #4CAF50;
  border-color: #4CAF50;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .solidarity-shop {
    padding: 1rem;
    min-height: 300px;
  }

  .solidarity-shop-content {
    padding: 1.5rem;
    width: calc(100% - 2rem); /* Tenir compte du padding */
    margin: 0 1rem;
  }

  .solidarity-shop h3 {
    font-size: 1.5rem;
  }

  .shop-description {
    font-size: 1rem;
  }

  .code-display {
    width: 90%;
    max-width: 280px;
    justify-content: space-between;
    padding: 0.8rem 1rem;
  }

  .copy-button {
    padding: 0.5rem;
    margin-left: 0.5rem;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }

  .code {
    font-size: 1.2rem;
  }

  .btn-shop {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
    width: auto;
    max-width: calc(100% - 2rem);
  }
}