.footer {
    background-color: #2a2a2a;
    color: white;
    padding: 3rem 0 1rem;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer-section {
    flex: 1;
    min-width: 250px;
    margin-bottom: 2rem;
  }
  
  .footer-section h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #ff6b00;
  }
  
  .footer-section p, .footer-section a {
    margin-bottom: 0.5rem;
    color: #ffffff;
    text-decoration: none;
  }
  
  .social-links {
    display: flex;
    flex-direction: column;
  }
  
  .social-links a {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .social-links img {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.5rem;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255,255,255,0.1);
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      width: 100%;
      text-align: center;
    }
  
    .social-links {
      flex-direction: row;
      justify-content: center;
    }
  
    .social-links a {
      margin: 0 1rem;
    }
  }