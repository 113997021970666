.sponsors {
    background-color: #2a2a2a;
    color: white;
    padding: 4rem 2rem;
    text-align: center;
}

.sponsors-content {
    max-width: 1200px;
    margin: 0 auto;
}

.sponsors h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.sponsors p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.btn-cta {
    background-color: #ff6b00;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
    max-width: 300px;
  }

  .btn-cta:hover {
    background-color: #ff8c00;
    transform: scale(1.05);
  }

  .or-divider {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    margin: 1rem 0;
  }
    
.sponsors-benefits {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 3rem;
}

.sponsors-benefits h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.sponsors-benefits ul {
    list-style-type: none;
    padding: 0;
}

.sponsors-benefits li {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.current-sponsors {
    margin-bottom: 3rem;
}

.current-sponsors h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.contact-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .contact-form-container {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    max-width: 500px;
    width: 90%;
  }
  
  .close-form {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .sponsor-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .sponsor-logo {
    max-width: 150px;
    height: auto;
  }

.sponsor-testimonial {
    max-width: 800px;
    margin: 0 auto;
}

.sponsor-testimonial blockquote {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 1rem;
}

.sponsor-testimonial cite {
    font-size: 1rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .sponsors h2 {
        font-size: 2rem;
    }

    .sponsors p {
        font-size: 1rem;
    }

    .btn-cta {
        font-size: 1rem;
        padding: 0.8rem 2rem;
    }

    .sponsors-benefits h3,
    .current-sponsors h3 {
        font-size: 1.5rem;
    }

    .sponsor-logo {
        width: 100px;
        height: 100px;
    }

    .sponsor-actions {
        flex-direction: row;
        justify-content: center;
      }
      
      .or-divider {
        margin: 0 1rem;
      }    
}

.sponsor-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .paypal-form {
    display: inline-block;
  }

  .btn-donate {
    background-color: #00a0df; /* Couleur Hello Asso */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .btn-donate:hover {
    background-color: #0089be;
  }
  
  .btn-donate img {
    height: 20px;
    width: auto;
    margin-left: 10px; /* Ajoute un peu d'espace entre le texte et le logo */
  }